import React from 'react'
import Header from '../Mycomponents/Header';
import Products from '../Mycomponents/Products';
import Footer from '../Mycomponents/Footer';
import Innerbanner from '../Mycomponents/Innerbanner';

const Ourproducts = () => {
    return (
       <>
            <Header />
            <Innerbanner PageTitle="Our Products" />           
            <Products />
            <Footer />
       </>
    )
}

export default Ourproducts
