const ProductApis = [
    {
        id: 1,
        title: "Tshirt",
        category: "tshirt",
        description: "Tshirt description This is Tshirt description Tshirt description",
        price: "155",
        image: 'images/blur.jpg'
    },
    {
        id: 2,
        title: "Shoes",
        category: "shoes",
        description: "Shoes description This is Tshirt description Tshirt description",
        price: "55",
        image: 'images/dog.jpg'
    },
    {
        id: 3,
        title: "Jeans",
        category: "shoes",
        description: "Jeans description This is Tshirt description Tshirt description",
        price: "99",
        image: 'images/grayscale.jpg'
    }
    ,
    {
        id: 4,
        title: "Cap",
        category: "cap",
        description: "cap description This is Tshirt description Tshirt description",
        price: "299",
        image: 'images/road.jpg'
    },
    {
        id: 5,
        title: "Long Tshirt",
        category: "tshirt",
        description: "Tshirt description This is Tshirt description Tshirt description",
        price: "15",
        image: 'images/cup.jpg'
    },
    {
        id: 6,
        title: "Casual shoes",
        category: "shoes",
        description: "Shoes description This is Tshirt description Tshirt description",
        price: "159",
        image: 'images/girl.jpg'
    },
    {
        id: 7,
        title: "Jeans",
        category: "jeans",
        description: "Jeans description This is Tshirt description Tshirt description",
        price: "199",
        image: 'images/tower.jpg'
    }
    ,
    {
        id: 8,
        title: "Cap",
        category: "cap",
        description: "cap description This is Tshirt description Tshirt description",
        price: "850",
        image: 'images/game.jpg'
    }
]
export default ProductApis;