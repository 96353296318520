import React from 'react'
import { NavLink } from 'react-router-dom'

const Innerbanner = ({PageTitle}) => {
    return (
        <div>
            <section className="banner innerbanner bg">
                <h6>Welcome To</h6>
                <h1>{PageTitle}</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <NavLink to="/" className="button">Back to Home</NavLink>
            </section>
        </div>
    )
}

export default Innerbanner
