import React from 'react'
import Error404 from '../Mycomponents/Error404'
import Footer from '../Mycomponents/Footer'
import Header from '../Mycomponents/Header'

const Error = () => {
    return (
        <div>
            <Header/>
            <Error404 />
            <Footer/>            
        </div>
    )
}

export default Error
