import React, {useState} from 'react'
import ServiceApi from '../Api/ServiceApi'
import { NavLink } from 'react-router-dom'
const Ourservices = () => {
    const [servState, setServState] = useState(ServiceApi)
    return (
        <>
        <section className="services">
            <div className="container">
                <h2 class="text-center">Our Services</h2>
                <div className="row">
                    {
                        servState.map((servElem) => {
                        return(
                            <> 
                            <div className="col-md-4">
                                <div className="card" >
                                    <div className="card-body">
                                        <i className={`fa ${servElem.icon}`} aria-hidden="true"></i>
                                        <h5 className="card-title">{servElem.title}</h5>
                                        <p className="card-text">{servElem.content}</p>
                                        {
                                            (window.location.pathname === '/services')
                                            ? ""
                                            : <NavLink to="/services" className="btn btn-primary button center ">Go somewhere</NavLink>

                                        }
                                        
                                    </div>
                                </div>
                            </div>    
                            </>
                        )
                        })
                    }            
                </div>
            </div>
        </section>
        </>
    )
}

export default Ourservices
