import React from 'react'
import { NavLink } from 'react-router-dom'
const Homebanner = () => {
    return (
        <>
           <section className="banner bg">
                <h6>Welcome To</h6>
                <img src="images/bannertext.png" alt=""/>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <NavLink to="/products" className="button">SHOP NOW</NavLink>
            </section>
        </>
    )
}

export default Homebanner
