import React from 'react'
import { NavLink } from 'react-router-dom'

const Header = () => {
    return (
        <>
        <header>
            <div className="topbar">
                <div className="container-fluid">                    
                        <div className="col-md-6">
                            <ul className="">
                                <li><i className="fa fa-phone"></i> 123-456-7890</li>
                                <li><i className="fa fa-envelope-o"></i> contact@youremail.com</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul className="pull-right">
                                <li><a href="javascript:;" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                <li><a href="javascript:;" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="javascript:;" target="_blank"><i className="fa fa-facebook"></i></a></li>                                
                            </ul>
                        </div>                   
                </div>
            </div>
            <div className="head">
                <div className="container-fluid">
                    <div className="col-md-1">
                        <NavLink to="/"><img src="images/logo.png" alt="Logo"/></NavLink>
                    </div>
                    <div className="col-md-10">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <NavLink className="nav-link" to="/">HOME </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/about">ABOUT US</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/products">Our Products</NavLink>
                                </li>
                                
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/contact">Contact us</NavLink>
                                </li>					  
                            </ul>
                        
                        </div>
                        </nav>
                    </div>
                    <div className="col-md-1">
                        <ul className="links">
                            <li><a href="#">
                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                <span className="cartquant">0</span>
                            </a></li>
                            <li><a href="#">
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        </>
    )
}

export default Header
