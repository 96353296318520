const ServiceApi = [
    {
        icon: "fa-file-text-o",
        title: 'Content Management',
        content: "Some quick example text to build on the card title and make up the bulk of the card's content."
    },
    {
        icon: "fa-diamond",
        title: 'Creative Services',
        content: "Some quick example text to build on the card title and make up the bulk of the card's content."
    },
    {
        icon: "fa-snowflake-o",
        title: 'Non-profit Services',
        content: "Some quick example text to build on the card title and make up the bulk of the card's content."
    }
]
export default ServiceApi;