import './App.css';
import Home from './Pages/Home';
import Services from './Pages/Services';
import About from './Pages/About';
import Ourproducts from './Pages/Ourproducts';
import Contact from './Pages/Contact';
import { Route, Switch } from 'react-router';
import Error from './Pages/Error';


function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/services" component={Services}></Route>
        <Route path="/about" component={About}></Route>
        <Route path="/products" component={Ourproducts}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route component={Error}></Route>
      </Switch>
         
    </>
  );
}

export default App;
