import React from 'react'
import Header from '../Mycomponents/Header';
import Aboutus from '../Mycomponents/Aboutus';
import Footer from '../Mycomponents/Footer';
import Innerbanner from '../Mycomponents/Innerbanner';

const About = () => {
    return (
        <>
            <Header />
            <Innerbanner PageTitle="About Us" />           
            <Aboutus />
            <Footer />
        </>
    )
}

export default About
