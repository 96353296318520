import React, {useState} from 'react'
import ProductApis from '../Api/ProductApis'
const Products = () => {
    const [getProducts, setGetProducts] = useState(ProductApis);
    const uniqueMenu = ["All", ... new Set(ProductApis.map((menuElem)=> {
       return menuElem.category;
    }))]
    const getPro = (category) => {
        if(category === "All"){
            setGetProducts(ProductApis)
            return;
        }
           const itemsFetch = ProductApis.filter((currentElem) => {               
           return currentElem.category == category;
        })
        setGetProducts(itemsFetch);      
      };    
    return (
        <>
        <section className="products">
                <div className="container">
                    <h2 className="text-center">Collector's Corner</h2>
                    <div class="btn-group center" role="group" aria-label="Basic example">
                    {
                        uniqueMenu.map((mElem) => {
                            return (
                                <>
                                  <button type="button" class="btn btn-primary" onClick={()=> getPro(mElem)}>{mElem}</button>
                                </>
                            )
                        })
                    }
                 
                    </div>
                    <div className="row">                                    
                        {
                            getProducts.map((curElem) => {
                                return (
                                    <>                                    
                                        <div className="col-md-3">
                                            <div className="procard">
                                                <div className="prothumb">
                                                    <img src={curElem.image} />
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <h4><a href="javascript:;"> {curElem.title}</a></h4>
                                                        <small>Category: {curElem.category}</small>
                                                    </div>
                                                    <div className="col-md-3">
                                                    <h5 class="pull-right">${curElem.price}</h5>
                                                    </div>
                                                </div>
                                                <p>{curElem.description}</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                    
                </div>
            </section>
        </>
    )
}
export default Products