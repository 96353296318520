import React from 'react'

const Footer = () => {
    return (
        <>
            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-md-3 col-1">
                        
                            <h4>Contact Information</h4>
                            <ul>
                                <li><i class="fa fa-map-marker"></i> Consult aid, Newyork, 1st block 1234 Building no 8, floor 3 </li>
                                <li><i class="fa fa-envelope"></i> Email:  youremail@aol.com </li>
                                <li><i class="fa fa-phone"></i> Phone:  718-414-4778 </li>
                            </ul>
                        
                        </div>
                        
                        <div class="col-md-5 col-2">
                        <div class="box">
                            <h4>Navigation</h4>
                            <ul>
                                <li><a href="javascript:;">HOME</a></li>
                                <li><a href="javascript:;">ABOUT US</a></li>
                                <li><a href="javascript:;">Collector's Corner</a></li>
                                <li><a href="javascript:;">New vinyl</a></li>
                                <li><a href="javascript:;">New CDs</a></li>
                                <li><a href="javascript:;">Used CDs</a></li>
                            </ul>
                            <ul class="pull-right">
                                <li><a href="javascript:;">New Cassette</a></li>
                                <li><a href="javascript:;">Used Cassettes</a></li>
                                <li><a href="javascript:;">Indie music releases</a></li>
                                <li><a href="javascript:;">Contact us</a></li>
                            </ul>
                        </div>
                        </div>
                        
                        <div class="col-md-4">
                            <h4>Newsletter</h4>
                            <p>Enter your email & subcribing newsletter</p>
                            <form id="newsletter">
                                <i class="fa fa-envelope-o"></i>
                                <input type="email" placeholder="Email address" required/>
                                <button>Subscribe</button>
                            </form>
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fa fa-google-plus"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <p>2021 ©  U Love Music. All rights reserved</p>
                        </div>
                        <div class="col-md-6">
                            <ul class="pull-right">
                                <li><a href="#">Terms and Condition</a></li>
                                <li><a href="#">Privacy and Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
