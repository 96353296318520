import React from 'react'
import Header from '../Mycomponents/Header';
import Homebanner from '../Mycomponents/Homebanner';
import Aboutus from '../Mycomponents/Aboutus';
import Products from '../Mycomponents/Products';
import Footer from '../Mycomponents/Footer';
import Ourservices from '../Mycomponents/Ourservices';

const Home = () => {
    return (
        <>
            <Header />
            <Homebanner />
             <Ourservices/>
            <Aboutus />
            <Products />
            <Footer />
        </>
    )
}

export default Home
