import React from 'react'
import Header from '../Mycomponents/Header';
import Footer from '../Mycomponents/Footer';
import Innerbanner from '../Mycomponents/Innerbanner';
import Ourservices from '../Mycomponents/Ourservices';

const Services = () => {
    return (
        <>
            <Header />
            <Innerbanner PageTitle="Our Services"/>          
            <Ourservices />
            <Footer />
        </>
    )
}

export default Services