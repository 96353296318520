import React from 'react'
import Header from '../Mycomponents/Header';
import Footer from '../Mycomponents/Footer';
import Innerbanner from '../Mycomponents/Innerbanner';
const Contact = () => {
    return (
        <>
            <Header />
            <Innerbanner PageTitle="Contact Us" />           
            Coming soon
            <Footer />
        </>
    )
}

export default Contact
